<template>
    <v-container>
      <div v-if="esSupervisorRegistros" class="d-flex flex-wrap flex-md-nowrap align-center mb-4" style="gap: 16px;">
        <ProgresiveLoadAutocomplete
            v-model="institucionSeleccionada"
            :pageable="instituciones"
            :loading="instituciones.isLoading"
            :transform-item="mapearInstitucion"
            style="flex-basis: 600px;"
            class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
            label="Centro escolar"
            placeholder="Seleccione un centro escolar"
            itemText="nombre_completo"
            itemValue="id"
            hide-details
            @search="buscarInstitucion"
            @load-more="cargarMasInstituciones"
            clearable
        />
        <v-select
            v-model="anio_fiscal"
            class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
            label="Año fiscal"
            placeholder="Seleccione un año fiscal"
            outlined
            hide-details
            :items="anios_fiscales"
            :loading="cargandoCatalogoAnios"
            item-text="anio"
            item-id="id"
            clearable
        />
        <v-btn
            @click.stop="buscarRegistrosFondos"
            x-large
            class="flex-grow-1 flex-md-grow-0"
            color="primary"
        >
          <v-icon class="mr-1" color="#FFF !important">mdi-magnify</v-icon>
          Buscar
        </v-btn>
      </div>
      <RetryDataLoading :loadable="registrosFondos" :retry-handler="cargarRegistrosFondos">
        <DataTableComponent
            :items="registrosFondos.data"
            :show_loading="registrosFondos.isLoading"
            :total_registros="totalRegistrosFondos"
            :headers="headers"
            @paginar="paginarRegistrosFondos"
            v-models:pagina="paginacionRegistrosFondos.pagina"
            v-models:select="paginacionRegistrosFondos.registrosPorPagina"
        >
          <template v-slot:item.centro_escolar="{ item }">
            <span>
              {{ item.Institucion.codigo }} - 
              {{ item.Institucion.nombre  }}
            </span>
          </template>
          <template v-slot:[`item.anio_fiscal`]="{ item }">
            {{ item.anio_fiscal ?? '--' }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            ${{ Number(item.monto_disponible || 0).toFixed(2) }}
          </template>
          <template v-slot:[`item.monto_establecido`]="{ item }">
            ${{ Number(item.monto_establecido || 0).toFixed(2) }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <div class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      @click.stop="$router.push(`/revision-registros-fondos-centros-escolares/${item.id}`)"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      icon
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver registros</span>
              </v-tooltip>
            </div>
          </template>
        </DataTableComponent>
      </RetryDataLoading>
    </v-container>
  </template>
  <script>
  import DataTableComponent from '@/components/DataTableComponent.vue';
  import {ProgresiveLoadAutocomplete, RetryDataLoading} from '@/components/utils';
  import {createPageable, isResponseSuccesful, setPageableResponse, togglePageable} from '@/utils/loadable';
  
  export default {
    components: {DataTableComponent, ProgresiveLoadAutocomplete, RetryDataLoading},
    data: () => ({
      headers: [
        {align: 'center', sortable: false, text: 'Nombre', value: 'nombre'},
        {align: 'center', sortable: false, text: 'Centro escolar', value: 'centro_escolar'},
        {align: 'center', sortable: false, text: 'Año fiscal', value: 'anio_fiscal'},
        {align: 'center', sortable: false, text: 'Motivo', value: 'motivo'},
        {align: 'center', sortable: false, text: 'Monto disponible', value: 'monto_disponible'},
        {align: 'center', sortable: false, text: 'Monto establecido', value: 'monto_establecido'},
        {align: 'center', sortable: false, text: 'Acciones', value: 'acciones'},
      ],
      // Instituciones
      institucionSeleccionada: null,
      paginacionInstituciones: {
        pagina: 1,
        registrosPorPagina: 30,
      },
      instituciones: createPageable([], 30),
      filtroInstitucion: '',
      // Registros
      registrosFondos: createPageable([], 10),
      paginacionRegistrosFondos: {
        pagina: 1,
        registrosPorPagina: 10,
      },
      // Anios fiscales
      cargandoCatalogoAnios: false,
      anios_fiscales: [],
      anio_fiscal: null,
    }),
    computed: {
      totalRegistrosFondos() {
        return this.registrosFondos.pagination.total_rows;
      },
      esSupervisorRegistros() {
        return this.haveRoles(['ROLE_SUPERVISOR_REGISTROS_FONDO_COMPRA_CENTRO_ESCOLAR_VIEW']);
      },
    },
    methods: {
      // Instituciones
      mapearInstitucion(institucion) {
        return ({ ...institucion, nombre_completo: `${institucion.codigo} - ${institucion.nombre}` });
      },
      async cargarInstituciones() {
        const filtros = {
          pagination: true,
          per_page: this.paginacionInstituciones.registrosPorPagina,
          page: this.paginacionInstituciones.pagina,
          busqueda: this.filtroInstitucion,
        };
  
        togglePageable(this.instituciones);
        const copiaInstituciones = this.instituciones.data;
        const {data, headers} = await this.services.ComprasCentroEscolar.cargarInstituciones(filtros);
        setPageableResponse(this.instituciones, data, headers, {skipOnSuccess: true, persistDataOnError: true});
  
        if (!isResponseSuccesful(data)) {
          // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
          const {pagina} = this.paginacionInstituciones;
          this.paginacionInstituciones.pagina = pagina > 1 ? pagina - 1 : 1;
          return;
        }
        ;
  
        this.instituciones.data = copiaInstituciones.concat(data.data);
      },
      async buscarInstitucion(termino) {
        if (this.filtroInstitucion === termino) return;
        this.filtroInstitucion = termino;
        this.instituciones.data = [];
        this.paginacionInstituciones.pagina = 1;
        this.cargarInstituciones();
      },
      async cargarMasInstituciones() {
        if (this.instituciones.isLoading) return;
        const {page, per_page, total_rows} = this.instituciones.pagination;
        const currentlyLoaded = page * per_page;
        if (!(currentlyLoaded < total_rows)) return;
  
        this.paginacionInstituciones.pagina = page + 1;
        this.paginacionInstituciones.registrosPorPagina = per_page;
        this.cargarInstituciones();
      },
      // Registros
      buscarRegistrosFondos() {
        this.registrosFondos = createPageable([], 10);
        this.paginacionRegistrosFondos = {
          pagina: 1,
          registrosPorPagina: this.paginacionRegistrosFondos.registrosPorPagina,
        };
  
        this.cargarRegistrosFondos();
      },
      async cargarRegistrosFondos() {
        const filtros = {
          id_institucion: this.institucionSeleccionada,
          pagination: true,
          page: this.paginacionRegistrosFondos.pagina,
          per_page: this.paginacionRegistrosFondos.registrosPorPagina,
          anio_fiscal: this.anio_fiscal,
        };
  
        togglePageable(this.registrosFondos);
        const {data, headers} = await this.services.ComprasCentroEscolar.listarFondos(filtros);
        setPageableResponse(this.registrosFondos, data, headers);
      },
      paginarRegistrosFondos(paginacion) {
        const {cantidad_por_pagina, pagina} = paginacion;
        this.paginacionRegistrosFondos = {
          pagina,
          registrosPorPagina: cantidad_por_pagina,
        };
  
        this.cargarRegistrosFondos();
      },
      async cargarCatalogoAnios() {
        try {
          this.cargandoCatalogoAnios = true;
          const { data } = await this.services.Auth.obtenerAniosFiscales();
          this.anios_fiscales = data;
        } catch (error) {}
        finally {
          this.cargandoCatalogoAnios = false;
        }
      },
    },
    created() {
      if (this.esSupervisorRegistros) this.cargarInstituciones();
      this.cargarCatalogoAnios();
      this.cargarRegistrosFondos();
    },
  }
  </script>
  